export class StatusChecker {
  
  constructor(config) {
    this.serviceUrl = config.domain + config.serviceUrl;
    this.interval = config.interval;
    this.errorUrl = config.domain + config.errorUrl;
    this.status = {
      'MAINTENANCE' : 'maintenance',
      'ALIVE': 'alive'
    };

    this.checkStatus = this.checkStatus.bind(this);
    this.checkStatusWorker = null;
  }

  handlerSuccess(xhr) {
    const appStatus = JSON.parse(xhr.responseText);
    if (appStatus.status === this.status.MAINTENANCE){
      this.stop();
      self.location.replace(this.errorUrl);
    }
  }

  handlerError(xhr) {
    window.console.error( xhr.status + ': ' + xhr.statusText );
  }

  checkStatus() {
    const xhr = new XMLHttpRequest();
    var that = this;

    xhr.onreadystatechange = function() {
      if (xhr.readyState != 4) return;
      
      if (xhr.status != 200) {
        that.handlerError(xhr);
      } else {
        that.handlerSuccess(xhr);
      }
    }

    xhr.open('GET', this.serviceUrl, true);
    xhr.send();
  }

  start() {
    this.checkStatus();
    this.checkStatusWorker = window.setInterval(this.checkStatus, this.interval);
    window.console.log( 'Worker started!' );
  }

  stop() {
    if(this.checkStatusWorker !== null){
      window.clearInterval(this.checkStatusWorker);
      window.console.log( 'Worker stopped!' );
    }
    else{
      window.console.log( 'Worker does not exist.' );
    }
  }
}
