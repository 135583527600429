/* eslint no-undef: 0 */

import {StatusChecker} from './StatusChecker';

(() => {
  const statusCheckConfig = {
    serviceUrl: '/wpng/status.json',
    interval: 60000,
    errorUrl: '/wpng/errors/err5xx.html',
    domain: ''
  };

  // /status/maintenance.json
  // /status/alive.json

  
  if ((typeof wpng !== 'undefined') && (typeof wpng.domainUrl !== 'undefined')) {
    statusCheckConfig.domain = wpng.domainUrl;
  }

  let sc = new StatusChecker(statusCheckConfig);
  sc.start();
})()
